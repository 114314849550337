import { FC } from 'react'
import Image from 'next/image'
import { Heading, Text } from '@/components/ui'

export interface CardContentProps {
  title: string
  subtitle?: string
  description?: string
  price?: string
  image?: string
  icon?: JSX.Element
  link?: string
}

export const CardContent: FC<CardContentProps> = ({
  image,
  icon,
  subtitle,
  title,
  description,
  price,
  link
}) => (
  <div
    className={`h-full bg-neutral-100 dark:bg-neutral-800 rounded-xl ${
      link &&
      'hover:bg-neutral-200 dark:hover:bg-neutral-700 hover:border-neutral-300 dark:hover:border-neutral-600'
    } border border-neutral-200 dark:border-neutral-700`}
  >
    {image && (
      <Image
        src={image}
        alt={`${title} resource`}
        width={1920}
        height={1080}
        sizes="100vw"
        className="rounded-t-xl"
      />
    )}

    <div className="p-7">
      {icon && (
        <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
          {icon}
        </div>
      )}

      {subtitle && <Text>{subtitle}</Text>}

      <Heading visualLevel="4">{title}</Heading>

      {description && <Text className="mt-2">{description}</Text>}

      {price && (
        <Text color="corporate" className="mt-2">
          ${price}
        </Text>
      )}
    </div>
  </div>
)
