import { FC } from 'react'
import { Logo } from './Logo'

export interface LogoTextProps {
  height?: number
}

export const LogoText: FC<LogoTextProps> = ({ height = 24 }) => (
  <>
    <Logo height={height} />
    <span
      className="mx-2 text-xl font-extrabold md:inline select-none"
      title={'Playlolly: Fivem Server & Resources'}
    >
      Playlolly
    </span>
  </>
)
