import { FC, ReactNode } from 'react'
import Link from 'next/link'
import { LogoText } from './LogoText'

function FooterLink({ href, children }: { href: string; children: ReactNode }) {
  const classes =
    'text-sm text-gray-500 no-underline hover:text-gray-700 hover:dark:text-white transition'
  if (href.startsWith('http')) {
    return (
      <a href={href} className={classes}>
        {children}
      </a>
    )
  }
  return (
    <Link href={href} className={classes}>
      {children}
    </Link>
  )
}

function FooterHeader({ children }: { children: ReactNode }) {
  return <h3 className="text-sm text-black dark:text-white">{children}</h3>
}

const navigation = {
  resources: [
    { name: 'Documentation', href: '/docs' },
    { name: 'Changelogs', href: '/changelogs' }
  ],
  social: [
    { name: 'Discord', href: 'https://discord.playlolly.net/' },
    {
      name: 'YouTube',
      href: 'https://www.youtube.com/@playlolly'
    },
    { name: 'Twitter', href: 'https://twitter.com/playlollynet' }
  ],
  legal: [
    { name: 'Privacy Policy', href: '/legal/privacy-policy' },
    { name: 'Terms and Conditions', href: '/legal/terms-and-conditions' },
    { name: 'Return Policy', href: '/legal/return-policy' },
    { name: 'Disclaimer', href: '/legal/disclaimer' }
  ]
}

export const Footer: FC = () => (
  <footer
    className="bg-gray-100 dark:bg-neutral-900 dark:border-gray-800 w-full"
    aria-labelledby="footer-heading"
  >
    <h2 id="footer-heading" className="sr-only">
      Footer
    </h2>
    <div className="mx-auto max-w-[90rem]">
      <div>
        <div className="w-full pb-12 mx-auto">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="grid grid-cols-1 gap-8 xl:col-span-2">
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 md:gap-8">
                <div className="mt-12 md:!mt-0">
                  <FooterHeader>Resources</FooterHeader>
                  <ul role="list" className="mt-4 space-y-1.5 list-none ml-0">
                    {navigation.resources.map(item => (
                      <li key={item.name}>
                        <FooterLink href={item.href}>{item.name}</FooterLink>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:!mt-0">
                  <FooterHeader>Social</FooterHeader>
                  <ul role="list" className="mt-4 space-y-1.5 list-none ml-0">
                    {navigation.social.map(item => (
                      <li key={item.name}>
                        <FooterLink href={item.href}>{item.name}</FooterLink>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:!mt-0">
                  <FooterHeader>Legal</FooterHeader>
                  <ul role="list" className="mt-4 space-y-1.5 list-none ml-0">
                    {navigation.legal.map(item => (
                      <li key={item.name}>
                        <FooterLink href={item.href}>{item.name}</FooterLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="mt-12 xl:!mt-0">
              <FooterHeader>About Us</FooterHeader>
              <p className="mt-4 text-sm text-gray-500">
                Powerful FiveM EV resources. With our resources, you have
                everything you need for a realistic EV vehicle experience, right
                on your FiveM server.
              </p>
            </div>
          </div>
        </div>

        <div className="md:flex md:items-center md:justify-between">
          <span>
            <div className="flex items-center">
              <LogoText />
            </div>
            <p className="mt-4 text-xs text-gray-500">
              &copy; {new Date().getFullYear()} Playlolly. All rights reserved.
              | Any trademarks used belong to their respective owners.
            </p>
          </span>

          <Link href="https://status.playlolly.net" className="hidden md:block">
            <div className="flex p-2 bg-white rounded-xl dark:bg-neutral-900 border border-gray-200 dark:border-neutral-700 hover:bg-neutral-200 dark:hover:bg-neutral-700 hover:border-neutral-300 dark:hover:border-neutral-600">
              <span className="flex items-center">
                <span className="pr-2 text-xs text-day-800">Status:</span>
                <span className="relative inline-flex h-2.5 w-2.5">
                  <span className="absolute inline-flex h-full w-full animate-ping rounded-md opacity-75 bg-tesla-green"></span>
                  <span className="relative inline-flex h-2.5 w-2.5 rounded-md bg-tesla-green"></span>
                </span>
                <span className="pl-2 text-xs text-tesla-green">
                  Operational
                </span>
              </span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </footer>
)
