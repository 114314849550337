import React, { FC } from 'react'
import cn from 'clsx'
import styles from './Text.module.css'
import { BaseComponentProps } from '@/types/BaseComponent'

interface TextProps extends BaseComponentProps {
  size?: 'sm' | 'base' | 'lg'
  color?: 'primary' | 'secondary' | 'corporate'
  weight?: 'medium' | 'bold'
}

export const Text: FC<TextProps> = ({
  size = 'base',
  color = 'secondary',
  weight = 'medium',
  children,
  className
}) => {
  const rootClassName = cn(
    className,
    styles.root,
    styles[`${color}`],
    styles[`${size}`],
    styles[`${weight}`]
  )

  return <p className={rootClassName}>{children}</p>
}
