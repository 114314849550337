import React, { FC, EventHandler, MouseEvent, MouseEventHandler } from 'react'
import cn from 'clsx'
import { BaseComponentProps } from '@/types/BaseComponent'
import Link from 'next/link'
import { HiArrowPath } from 'react-icons/hi2'

type ButtonColor = 'primary' | 'secondary' | 'corporate'
type ButtonSize = 'sm' | 'base' | 'lg'

interface ButtonProps extends BaseComponentProps {
  color?: ButtonColor
  size?: ButtonSize
  href?: string
  icon?: JSX.Element
  iconPosition?: 'left' | 'right'
  isDisabled?: boolean
  isLoading?: boolean
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>
}

const mapColorToClassName: Record<ButtonColor, string> = {
  primary:
    'bg-neutral-100 dark:bg-neutral-800 hover:bg-neutral-200 dark:hover:bg-neutral-700 border border-neutral 200 dark:border-neutral-700 hover:border-neutral-300 dark:hover:border-neutral-600',
  secondary:
    'bg-white dark:bg-neutral-900 hover:bg-neutral-200 dark:hover:bg-neutral-700 border border-neutral 200 dark:border-neutral-700 hover:border-neutral-300 dark:hover:border-neutral-600',
  corporate:
    'bg-tesla-red hover:bg-red-700 border border-tesla-red hover:border-red-700 text-white'
}
const mapSizeToClassName: Record<ButtonSize, string> = {
  sm: 'px-3 py-1 text-sm',
  base: 'px-5 py-3 text-base',
  lg: 'px-10 py-3 text-lg'
}

const ButtonBody: FC<ButtonProps> = ({
  icon,
  iconPosition,
  className,
  children
}) => (
  <div className={cn('flex justify-center items-center gap-x-2', className)}>
    {icon && iconPosition === 'left' && icon}
    {children}
    {icon && iconPosition === 'right' && icon}
  </div>
)

export const Button: FC<ButtonProps> = ({
  color = 'primary',
  size = 'base',
  href,
  icon,
  iconPosition = 'left',
  isDisabled = false,
  isLoading = false,
  onClick,
  className,
  children
}) => (
  <button
    disabled={isDisabled}
    onClick={!href ? onClick : undefined}
    className={cn(
      'rounded-xl',
      (isLoading || isDisabled) &&
        'cursor-not-allowed opacity-50 hover:bg-tesla-red hover:border-tesla-red',
      mapColorToClassName[color],
      className
    )}
  >
    {href && (
      <Link href={isDisabled ? '#' : href} onClick={onClick}>
        <ButtonBody
          size={size}
          icon={icon}
          iconPosition={iconPosition}
          className={cn(
            'group flex items-center justify-center font-medium text-center',
            mapSizeToClassName[size],
            !href && mapSizeToClassName[size]
          )}
        >
          {children}
        </ButtonBody>
      </Link>
    )}

    {!href && !isLoading && (
      <ButtonBody
        size={size}
        icon={icon}
        iconPosition={iconPosition}
        className={cn(
          'group flex items-center justify-center font-medium text-center',
          mapSizeToClassName[size],
          !href && mapSizeToClassName[size]
        )}
      >
        {children}
      </ButtonBody>
    )}

    {!href && isLoading && <HiArrowPath size={24} className="animate-spin" />}
  </button>
)
