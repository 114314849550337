import { LogoText, Footer } from '@/components/ui'
import { useRouter } from 'next/router'
import { DocsThemeConfig, useConfig } from 'nextra-theme-docs'

export default {
  sidebar: {
    defaultMenuCollapseLevel: 1,
    toggleButton: true,
    autoCollapse: true
  },
  useNextSeoProps() {
    const { asPath } = useRouter()
    if (asPath !== '/') {
      return {
        titleTemplate: '%s – Playlolly'
      }
    }
  },
  editLink: {
    text: ''
  },
  chat: {
    link: 'https://discord.playlolly.net'
  },
  feedback: {
    content: 'Questions? Join our Discord →'
  },
  logo: () => <LogoText />,
  head: () => {
    const { asPath } = useRouter()
    const { frontMatter } = useConfig()
    const url = `https://playlolly.net${asPath}`

    const socialCard =
      !frontMatter.title || frontMatter.ogImage
        ? `https://playlolly.net/assets/${
            frontMatter.ogImage ?? '/og-image.jpg'
          }`
        : `https://playlolly.net/api/generate-og-image?title=${frontMatter.title}`

    return (
      <>
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="theme-color" content="#000000" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Language" content="en-US" />
        <meta name="description" content={frontMatter.description} />
        <meta name="keywords" content={frontMatter.keywords} />

        <meta property="og:site_name" content="Playlolly" />
        <meta property="og:url" content={url} />
        <meta name="og:title" content={frontMatter.title} />
        <meta name="og:description" content={frontMatter.description} />
        <meta property="og:image" content={socialCard} />
        <meta property="og:image:url" content={socialCard} />
        <meta property="og:image:secure_url" content={socialCard} />
        <meta property="og:image:width" content="1920" />
        <meta property="og:image:height" content="1080" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:alt" content={frontMatter.title} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content={frontMatter.ogType ?? 'website'} />

        {frontMatter.ogType == 'product' && (
          <>
            <meta
              property="product:price:amount"
              content={frontMatter.ogPrice}
            />
            <meta property="product:price:currency" content="USD" />
            <meta property="product:availability" content="instock" />
            <meta
              property="product:retailer_item_id"
              content={frontMatter.ogTebexPackageId ?? '0'}
            />
          </>
        )}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@playlollynet" />
        <meta name="twitter:description" content={frontMatter.description} />
        <meta name="twitter:title" content={frontMatter.title} />
        <meta name="twitter:creator" content="@playlollynet" />
        <meta property="twitter:url" content={url} />
        <meta property="twitter:image" content={socialCard} />
        {frontMatter.ogType == 'product' && (
          <>
            <meta property="twitter:label1" content="Price" />
            <meta
              property="twitter:data1"
              content={`&#036;${frontMatter.ogPrice}.00`}
            />
            <meta property="twitter:label2" content="Availability" />
            <meta property="twitter:data2" content="In stock" />
          </>
        )}

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/favicon/favicon-16x16.png"
        />
        <link
          rel="mask-icon"
          href="/assets/favicon/safari-pinned-tab.svg"
          color="#000000"
        />
        <link rel="shortcut icon" href="/assets/favicon/favicon.ico" />
      </>
    )
  },
  footer: {
    text: Footer
  },
  toc: {
    float: true,
    backToTop: true
  },
  primaryHue: 358,
  i18n: [{ locale: 'en-US', text: 'English' }]
} as DocsThemeConfig
