import React, { FC } from 'react'
import cn from 'clsx'
import { BaseComponentProps } from '@/types/BaseComponent'

type HeadingLevel = '1' | '2' | '3' | '4' | '5'

interface HeadingProps extends BaseComponentProps {
  level?: HeadingLevel
  visualLevel?: HeadingLevel
}

const mapVisualLevelToClassName: Record<HeadingLevel, string> = {
  1: 'text-6xl sm:text-7xl lg:text-8xl',
  2: 'text-5xl xl:text-6xl',
  3: 'text-4xl',
  4: 'text-2xl',
  5: 'text-xl'
}

export const Heading: FC<HeadingProps> = ({
  level = '1',
  visualLevel,
  className,
  children
}) => {
  const Tag = `h${level}` as keyof JSX.IntrinsicElements

  if (!visualLevel) {
    visualLevel = level
  }

  return (
    <Tag
      className={cn(
        'font-bold tracking-tight leading-[1.1]',
        mapVisualLevelToClassName[visualLevel],
        className
      )}
    >
      {children}
    </Tag>
  )
}
