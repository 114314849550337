import { FC } from 'react'
import cn from 'clsx'
import styles from './Divider.module.css'
import { BaseComponentProps } from '@/types/BaseComponent'

export const Divider: FC<BaseComponentProps> = ({ className }) => (
  <div className={cn(className, 'py-20 lg:py-24')}>
    <section className="absolute left-0 right-0">
      <span
        className={cn(
          'w-full absolute white h-[1px] top-0 opacity-25',
          styles.gradientSectionBorderDivider
        )}
      />
      <span
        className={cn(
          styles.gradientSectionBorder,
          styles.gradientSectionBorderLeft,
          'dark:opacity-35 opacity-[0.15]'
        )}
      />
      <span
        className={cn(
          styles.gradientSectionBorder,
          styles.gradientSectionBorderRight,
          'dark:opacity-35 opacity-[0.15]'
        )}
      />
    </section>
  </div>
)
