import Link from 'next/link'
import { FC } from 'react'
import { CardContent, CardContentProps } from './fragments/CardContent'
import { BaseComponentProps } from '@/types/BaseComponent'

export interface CardProps extends BaseComponentProps, CardContentProps {}

export const Card: FC<CardProps> = ({
  title,
  subtitle,
  description,
  price,
  image,
  icon,
  link,
  className
}) => {
  return (
    <div className={`${link && 'cursor-pointer'} ${className}`}>
      {link ? (
        <Link href={link}>
          <CardContent
            title={title}
            subtitle={subtitle}
            description={description}
            price={price}
            image={image}
            icon={icon}
            link={link}
          />
        </Link>
      ) : (
        <CardContent
          title={title}
          subtitle={subtitle}
          description={description}
          price={price}
          image={image}
          icon={icon}
        />
      )}
    </div>
  )
}
