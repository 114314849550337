import Image, { StaticImageData } from 'next/image'
import cn from 'clsx'
import { Text } from '@/components/ui'
import { FC } from 'react'
import { BaseComponentProps } from '@/types/BaseComponent'

export interface ReviewProps extends BaseComponentProps {
  name: string
  avatar: StaticImageData
  date: string
  text: string
}

export const Review: FC<ReviewProps> = ({
  name,
  avatar,
  date,
  text,
  className
}) => (
  <div
    className={cn(
      'flex p-4 bg-neutral-100 rounded-xl dark:bg-neutral-800 border border-neutral-200 dark:border-neutral-700',
      className
    )}
  >
    <div className="flex-shrink-0 mr-4">
      <Image
        className="w-12 h-12 rounded-full border border-neutral-200 dark:border-neutral-700"
        width={42}
        height={42}
        src={avatar}
        alt={`${name} avatar`}
      />
    </div>

    <div>
      <div className="flex items-center space-x-1 text-sm">
        <Text color="primary" weight="bold">
          {name}
        </Text>
        <Text>• {date}</Text>
      </div>
      <Text>{text}</Text>
    </div>
  </div>
)
