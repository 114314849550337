import { Disclosure } from '@headlessui/react'
import { HiOutlinePlusCircle } from 'react-icons/hi'
import { Heading, Text } from '@/components/ui'
import { FC } from 'react'

export interface DisclosureItemProps {
  question: string
  answer: string
}

export const DisclosureItem: FC<DisclosureItemProps> = ({
  question,
  answer
}) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full justify-between items-center rounded-lg text-left text-sm font-medium hover:text-tesla-red">
            <Heading level="4">{question}</Heading>
            <HiOutlinePlusCircle
              size={26}
              className={`${open ? 'rotate-45 transform' : ''} shrink-0`}
            />
          </Disclosure.Button>
          <Disclosure.Panel>
            <Text>{answer}</Text>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
