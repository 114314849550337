import { Children, FC, Fragment, PropsWithChildren } from 'react'
import { HiOutlineChevronRight } from 'react-icons/hi2'
import cn from 'clsx'

export const Breadcrumb: FC<PropsWithChildren> = ({ children }) => {
  const childrenArray = Children.toArray(children)

  const childrenWtihSeperator = childrenArray.map((child, index) => {
    if (index !== childrenArray.length - 1) {
      return (
        <Fragment key={index}>
          {child}
          <HiOutlineChevronRight className="text-neutral-500 dark:text-neutral-400" />
        </Fragment>
      )
    }
    return child
  })

  return (
    <div
      className={cn(
        'hidden md:block bg-neutral-100 dark:bg-neutral-900 border-b border-neutral-200 dark:border-neutral-800'
      )}
    >
      <nav
        aria-label="breadcrumb"
        className=" mx-auto px-4 sm:px-6 py-2 lg:max-w-[90rem]"
      >
        <ol className="flex items-center space-x-2">{childrenWtihSeperator}</ol>
      </nav>
    </div>
  )
}
