import Link from 'next/link'
import { FC } from 'react'
import { Text } from '@/components/ui'
import { BaseComponentProps } from '@/types/BaseComponent'

interface BreadcrumbItemProps extends BaseComponentProps {
  href?: string
  isCurrent?: boolean
}

export const BreadcrumbItem: FC<BreadcrumbItemProps> = ({
  href,
  isCurrent = false,
  children
}) => {
  return (
    <li aria-current={isCurrent ? 'page' : undefined}>
      {(isCurrent || !href) && (
        <Text size="sm" weight="bold" color="primary">
          {children}
        </Text>
      )}
      {!isCurrent && href && (
        <Link href={href}>
          <Text size="sm" className="hover:text-tesla-red">
            {children}
          </Text>
        </Link>
      )}
    </li>
  )
}
