import { FC } from 'react'
import Lightbox, { SlideImage } from 'yet-another-react-lightbox'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Counter from 'yet-another-react-lightbox/plugins/counter'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import 'yet-another-react-lightbox/plugins/counter.css'
import Image from 'next/image'
import { HiChevronLeft, HiChevronRight, HiXMark } from 'react-icons/hi2'

export interface ImageCarouselProps {
  images: SlideImage[]
  index: number
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
}

export const ImageCarousel: FC<ImageCarouselProps> = ({
  images,
  index,
  isVisible,
  setIsVisible
}) => {
  return (
    <Lightbox
      open={isVisible}
      index={index}
      close={() => setIsVisible(false)}
      slides={images}
      plugins={[Thumbnails, Counter]}
      thumbnails={{ imageFit: 'cover' }}
      render={{
        iconClose: () => <HiXMark size={24} />,
        iconPrev: () => <HiChevronLeft size={24} />,
        iconNext: () => <HiChevronRight size={24} />,
        slide: ({ slide, rect }) => {
          const width = Math.round(
            Math.min(rect.width, (rect.height / slide.height) * slide.width)
          )
          const height = Math.round(
            Math.min(rect.height, (rect.width / slide.width) * slide.height)
          )

          return (
            <div style={{ position: 'relative', width, height }}>
              <Image
                src={slide.src}
                fill
                loading="eager"
                className="contain"
                alt={'alt' in slide ? slide.alt : ''}
                sizes={
                  typeof window !== 'undefined'
                    ? `${Math.ceil((width / window.innerWidth) * 100)}vw`
                    : `${width}px`
                }
              />
            </div>
          )
        }
      }}
    />
  )
}
